import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

function NasdaqHalts() {
  const fetchHalts = async () => {
    console.log("Fetching data...");
    const { data } = await axios.get('./halts'); // <-- Updated API URL
    console.log("Data fetched:", data);
    return data;
  };

  const { isLoading, error, data } = useQuery('halts', fetchHalts);

  console.log("isLoading:", isLoading);
  console.log("error:", error);
  console.log("data:", data);

  if (isLoading) return 'Loading...';

  if (error) {
    console.error("An error occurred:", error.message);
    return 'An error has occurred: ' + error.message;
  }

  // Get today's date in 'MM/DD/YYYY' format
  const today = new Date();
  const formattedToday = `${(today.getUTCMonth()+1).toString().padStart(2, '0')}/${today.getUTCDate().toString().padStart(2, '0')}/${today.getUTCFullYear()}`;

  console.log("today:", today);
  console.log("formattedToday:", formattedToday);

  // Filter and sort the data
  const filteredData = data
    .filter(item => item.HaltDate === formattedToday || item.ResumptionDate === formattedToday || item.ResumptionDate === null)
    .sort((a, b) => {
      const dateTimeA = a.HaltDate + ' ' + a.HaltTime;
      const dateTimeB = b.HaltDate + ' ' + b.HaltTime;
      if (dateTimeA < dateTimeB) return 1;
      if (dateTimeA > dateTimeB) return -1;
      return 0;
    });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Halt Date</th>
            <th>Halt Time</th>
            <th>Issue Symbol</th>
            <th>Issue Name</th>
            <th>Market</th>
            <th>Reason Code</th>
            <th>Resumption Date</th>
            <th>Resumption Quote Time</th>
            <th>Resumption Trade Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredData && filteredData.map((item, i) => (
            <tr key={i}>
              <td>{item.HaltDate}</td>
              <td>{item.HaltTime}</td>
              <td>{item.IssueSymbol}</td>
              <td>{item.IssueName}</td>
              <td>{item.Market}</td>
              <td>{item.ReasonCode}</td>
              <td>{item.ResumptionDate}</td>
              <td>{item.ResumptionQuoteTime}</td>
              <td>{item.ResumptionTradeTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NasdaqHalts;
