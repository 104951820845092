// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyASEV5h2InIER1wHlfn6KbZAce2QcIrpRg",
    authDomain: "traderdata-9ca56.firebaseapp.com",
    projectId: "traderdata-9ca56",
    storageBucket: "traderdata-9ca56.appspot.com",
    messagingSenderId: "279213119705",
    appId: "1:279213119705:web:0c32557208a6b5f49986c8",
    measurementId: "G-HJHN6MKB4H"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  const auth = firebase.auth();
  const analytics = firebase.analytics();
  
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider);
  };
  
  const signInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return auth.signInWithPopup(provider);
  };
  
  const signOut = () => {
    return auth.signOut();
  };
  
  export { auth, signInWithGoogle, signInWithTwitter, signOut };