import React from 'react';
import axios from 'axios';

function MarketSystemStatus() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchIPOs = async () => {
      try {
        const response = await axios.get('./ipos');
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching IPOS data:", error);
        setIsLoading(false);
      }
    };

    fetchIPOs();
  }, []);

  if (isLoading) return 'Loading...';

  return (
      <div>
          <table>
              <thead>
                  <tr>
                      <th>Time</th>
                      <th>Market</th>
                      <th>Status</th>
                  </tr>
              </thead>
              <tbody>
                  {data.map((item, i) => (
                      <tr key={i}>
                          <td>{item.Time}</td>
                          <td>{item.Market}</td>
                          <td>{item.Status}</td>
                      </tr>
                  ))}
              </tbody>
          </table>
      </div>
  );
}

export default MarketSystemStatus;
