import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

function TodaysSplits() {
  const fetchSplits = async () => {
    const { data } = await axios.get('./splits');
    return data;
  };

  const { isLoading, error, data } = useQuery('splits', fetchSplits);

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  const today = new Date();
  const formattedToday = `${today.getUTCFullYear()}-${(today.getUTCMonth()+1).toString().padStart(2, '0')}-${today.getUTCDate().toString().padStart(2, '0')}`;
  console.log(formattedToday);

  const todaysSplits = data.filter(split => split.date === formattedToday);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Type</th>
            <th>Issuer Name</th>
            <th>Ratio</th>
          </tr>
        </thead>
        <tbody>
          {todaysSplits.map((split, i) => (
            <tr key={i}>
              <td>{split.Ticker}</td>
              <td>{split.Type}</td>
              <td>{split['Issuer Name']}</td>
              <td>{split.Ratio}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TodaysSplits;
