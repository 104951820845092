import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, signOut } from '../firebase';  // Correct import
import '../Header.css';

function Header() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Add a listener for authentication state changes
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut();
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    }

    return (
        <header>
            <div className="header-container">
                <div className="title">
                    <Link to="/" className="nav-link">
                        <img src="/TraderData_weblogo.png" alt="TraderData" className="logo" />
                    </Link>
                </div>
                <div className="nav-links">
                    <Link to="/calendar" className="nav-btn">Calendar</Link>
                    <Link to="/corpactionstable" className="nav-btn">Corp Actions Table</Link>
                    { user ? (
                        <div className="dropdown">
                            <button className="dropbtn">Account</button>
                            <div className="dropdown-content">
                                <Link to="#" onClick={handleLogout}>Log out</Link>
                            </div>
                        </div>
                    ) : (
                        <Link to="/login" className="nav-link">Log In</Link>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;





