import React, { useEffect, useState } from 'react';
import axios from 'axios';

function EconCalendar() {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get('/economicData')
            .then((response) => {
                // Formatting the date without commas
                const today = new Date();
                const formattedToday = today.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit' }).replace(',', '');
                
                console.log("Formatted Today's Date:", formattedToday);  // <-- Logging for debugging

                const filteredData = response.data.filter(item => item.date === formattedToday);
                if (filteredData.length > 0) {
                    setData(filteredData);
                } else {
                    console.error("No data found for today.");
                }
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const headers = data[0];
    const rows = data.slice(1);

    return (
        <table>
            <thead>
                <tr>
                    <th>{headers["0"]}</th>
                    <th>{headers["1"]}</th>
                    <th>{headers["3"]}</th>
                    <th>{headers["4"]}</th>
                    <th>{headers["5"]}</th>
                    <th>{headers["6"]}</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((record, index) => (
                    <tr key={index}>
                        <td>{record["0"]}</td>
                        <td>{record["1"]}</td>
                        <td>{record["3"]}</td>
                        <td>{record["4"]}</td>
                        <td>{record["5"]}</td>
                        <td>{record["6"]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default EconCalendar;
