import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import '../CorpActionsTable.css';

const CorpActionsTable = () => {
    const [actions, setActions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedActionTypes, setSelectedActionTypes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);

    useEffect(() => {
        fetch('/corpactionstable')
            .then(response => response.json())
            .then(data => {
                setActions(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page on rows per page change
    };

    const handleActionTypeChange = (selectedOptions) => {
        setSelectedActionTypes(selectedOptions ? selectedOptions.map(option => option.value) : []);
        setCurrentPage(1); // Reset to first page on filter change
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setCurrentPage(1); // Reset to first page on date change
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const openModal = (action) => {
        setSelectedAction(action);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedAction(null);
    };

    const formatValue = (value) => {
        if (typeof value === 'object') {
            if ('$oid' in value) {
                return value.$oid;
            } else if ('$numberLong' in value) {
                return value.$numberLong;
            } else {
                return JSON.stringify(value);
            }
        }
        return value;
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const sortedActions = [...actions].sort((a, b) => {
        if (sortConfig.key) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const filteredActions = sortedActions.filter(action => 
        (selectedActionTypes.length === 0 || selectedActionTypes.includes(action.actionType)) &&
        (!selectedDate || new Date(action.effective_date).toLocaleDateString() === selectedDate.toLocaleDateString()) &&
        Object.values(action).some(value => 
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const totalPages = Math.ceil(filteredActions.length / rowsPerPage);
    const displayedActions = filteredActions.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const actionTypes = [...new Set(actions.map(action => action.actionType))].map(type => ({
        value: type,
        label: type
    }));

    const filteredKeys = (keys) => keys.filter(key => !['headline', 'link', 'notes'].includes(key));
    const selectedActionKeys = selectedAction ? filteredKeys(Object.keys(selectedAction)) : [];
    const halfLength = Math.ceil(selectedActionKeys.length / 2);
    const topLeftKeys = selectedActionKeys.slice(0, halfLength);
    const topRightKeys = selectedActionKeys.slice(halfLength);

    return (
        <div className="container">
            <header className="App-header">
                <h1>Corporate Actions Database</h1>
            </header>
            <div className="controls">
                <input 
                    type="text" 
                    placeholder="Search..." 
                    value={searchTerm} 
                    onChange={handleSearchChange} 
                />
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="Select effective date"
                    className="datepicker"
                />
                <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={filteredActions.length}>All</option>
                </select>
                <Select
                    isMulti
                    name="actionTypes"
                    options={actionTypes}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select action types..."
                    onChange={handleActionTypeChange}
                />
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('actionType')}>Action Type</th>
                            <th onClick={() => handleSort('IssuerName')}>Issuer Name</th>
                            <th onClick={() => handleSort('effective_date')}>Effective Date</th>
                            <th onClick={() => handleSort('record_date')}>Record Date</th>
                            <th onClick={() => handleSort('ExchgCD')}>Exchange Code</th>
                            <th onClick={() => handleSort('todayTicker')}>Ticker</th>
                            <th onClick={() => handleSort('split_ratio')}>Split Ratio</th>
                            <th onClick={() => handleSort('fractionalShares')}>Fractional Shares</th>
                            <th onClick={() => handleSort('notes')}>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedActions.map(action => (
                            <tr key={action._id.$oid} onClick={() => openModal(action)}>
                                <td>{action.actionType}</td>
                                <td>{action.IssuerName}</td>
                                <td>{action.effective_date}</td>
                                <td>{action.record_date}</td>
                                <td>{action.ExchgCD}</td>
                                <td>{action.todayTicker}</td>
                                <td>{action.split_ratio}</td>
                                <td>{action.fractionalShares}</td>
                                <td>{truncateText(action.notes, 300)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button 
                    onClick={() => setCurrentPage(1)} 
                    disabled={currentPage === 1}
                >
                    First
                </button>
                <button 
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>{currentPage} / {totalPages}</span>
                <button 
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
                <button 
                    onClick={() => setCurrentPage(totalPages)} 
                    disabled={currentPage === totalPages}
                >
                    Last
                </button>
            </div>
            <div className="results-count">
                Total Results: {filteredActions.length}
            </div>

            {selectedAction && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Corporate Action Details"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2>{selectedAction.actionType} - {selectedAction.todayTicker}</h2>
                    <button onClick={closeModal} className="close-button">Close</button>
                    <div className="modal-content">
                        <div className="modal-top">
                            <div className="modal-column">
                                {topLeftKeys.map(key => (
                                    <p key={key} className="single-spaced"><strong>{key}:</strong> {formatValue(selectedAction[key])}</p>
                                ))}
                            </div>
                            <div className="modal-column">
                                {topRightKeys.map(key => (
                                    <p key={key} className="single-spaced"><strong>{key}:</strong> {formatValue(selectedAction[key])}</p>
                                ))}
                            </div>
                        </div>
                        <div className="modal-bottom">
                            <p><strong>Headline:</strong> {selectedAction.headline}</p>
                            <p><strong>Link:</strong> {selectedAction.link}</p>
                            <p><strong>Notes:</strong> {selectedAction.notes}</p>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default CorpActionsTable;