// app.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { auth } from './firebase';
import Header from './components/Header';
import EconCalendar from './components/EconCalendar';
import Splits from './components/Splits';
import MarketSystemStatus from './components/MarketSystemStatus';
import NasdaqHalts from './components/NasdaqHalts';
import CorpActionsTable from './components/CorpActionsTable'; // Import CorpActionsTable
import TodayActions from './components/CorpActionsTable';
import './App.css';

const queryClient = new QueryClient();

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);

  const signInWithEmail = () => {
    auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setError(error.message);
      });
  };

  const signUpWithEmail = () => {
    auth.createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div className="login-container">
      {error && <div className="error-message">{error}</div>}
      <h3>{isSignUp ? 'Sign Up' : 'Sign In'}</h3>
      <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
      {isSignUp ? (
        <>
          <button onClick={signUpWithEmail}>Sign Up with Email</button>
          <p>Already have an account? <span onClick={() => setIsSignUp(false)}>Sign in here</span></p>
        </>
      ) : (
        <>
          <button onClick={signInWithEmail}>Sign in with Email</button>
          <p>Don't have an account? <span onClick={() => setIsSignUp(true)}>Sign up here</span></p>
        </>
      )}
    </div>
  );
}

function HomePage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="spinner">Loading...</div>;
  }

  return (
    <div>
      <div className="flex-container">
        <div className="flex-child magenta">
          <h3>Economic Calendar</h3>
          <EconCalendar />
          <h3>Splits</h3>
          <Splits />
        </div>
        <div className="flex-child green">
          <h3>Nasdaq IPO Messages</h3>
          <MarketSystemStatus />
        </div>
      </div>
      <div><h3>Halts</h3></div>
      <NasdaqHalts />
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  if (!user) return <Login />;

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/today-actions" element={<TodayActions />} />
          <Route path="/corpactionstable" element={<CorpActionsTable />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;



